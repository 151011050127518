import "babel-polyfill";
import "normalize.css";

import browserWrapper from "@src/rootBrowser";
import pageWrapper from "@src";

export const wrapRootElement = browserWrapper;
export const wrapPageElement = pageWrapper;

export const onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav");
    if (skipLink) {
      skipLink.focus();
    }
  }
};


// function initGTM() {
//   if (window.isGTMLoaded) {
//     return false;
//   }

//   window.isGTMLoaded = true;

//   const script = document.createElement('script');

//   script.type = 'text/javascript';
//   script.async = true;
//   script.src = `https://www.googletagmanager.com/gtag/js?id=G-ZTQLEKVQKV`;

//   script.onload = () => {
//     window.dataLayer = window.dataLayer || [];
//     function gtag() {
//       window.dataLayer.push(arguments);
//     }
//     gtag('js', new Date());

//     gtag('config', `G-ZTQLEKVQKV`);
//   };

//   document.head.appendChild(script);
// }

// function loadGTM(event) {
//   initGTM();
//   event.currentTarget.removeEventListener(event.type, loadGTM);
// }

// export const onClientEntry = () => {
//   document.onreadystatechange = () => {
//     if (document.readyState !== 'loading') {
//       setTimeout(initGTM, 3500);
//     }
//   };

//   document.addEventListener('scroll', loadGTM);
//   document.addEventListener('mousemove', loadGTM);
//   document.addEventListener('touchstart', loadGTM);
// };