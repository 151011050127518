// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-tags-index-tsx": () => import("./../src/templates/tags/index.tsx" /* webpackChunkName: "component---src-templates-tags-index-tsx" */)
}

